<template>
  <el-main>
    <el-form :model="ruleForm" class="el-form-search" label-width="130px">
      <el-form-item label="回收员姓名：">
        <el-input size="small" v-model="ruleForm.name" placeholder="请输入回收员信息"></el-input>
      </el-form-item>
      <el-form-item label="回收员手机号码：">
        <el-input size="small" v-model="ruleForm.tel" placeholder="请输入回收员手机号码"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader size="small" v-model="ruleForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker v-model="ruleForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="回收员姓名" align="center"></el-table-column>
      <el-table-column label="回收员信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nick_name" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="tel" label="回收员电话" align="center"></el-table-column>
      <el-table-column label="回收员类别" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '专职' : '兼职' }}
        </template>
      </el-table-column>
      <el-table-column prop="address_info" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="order_num" label="累计接单数" align="center"></el-table-column>
      <el-table-column prop="total_amout" label="累计接单金额" align="center">
        <template v-slot="{ row }">￥{{ Number(row.total_amout).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="是否启用" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_open" :active-value="1" :inactive-value="0" @change="isEnable(row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="注册时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="() => $router.push('/user/editRecycler?id=' + row.id)">编辑</el-button>
          <el-button type="text" @click="delRecyler(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    UserInfo,
    Paging,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        name: '',
        tel: '',
        create_time: [],
        area_id: [],
      },
      areaList: [],
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
    };
  },
  created() {
    if (this.$route.query.cityId) {
      this.ruleForm.area_id = JSON.parse(this.$route.query.cityId);
    }
    this.getArea();
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.ruleForm = {
        name: '',
        tel: '',
        create_time: [],
        area_id: [],
      };
      this.getDataList(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    getDataList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.name) {
        obj.name = this.ruleForm.name;
      }
      if (this.ruleForm.tel) {
        obj.tel = this.ruleForm.tel;
      }
      if (this.ruleForm.create_time && this.ruleForm.create_time.length) {
        obj.create_time = [Math.floor(this.ruleForm.create_time[0] / 1000), Math.floor(this.ruleForm.create_time[1] / 1000)];
        if (obj.create_time[0] === obj.create_time[1]) {
          obj.create_time[1] = obj.create_time[0] + 60 * 60 * 24 - 1;
        }
      }
      if (this.ruleForm.area_id.length) {
        obj.area_id = this.ruleForm.area_id[2];
      }
      this.$axios.post(this.$api.O2O.recycler.recylerList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除回收员
    delRecyler(row) {
      this.$confirm('此操作将永久删除该回收员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.O2O.recycler.delRecyler, { id: row.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            if (this.dataList.length === 1 && this.page !== 1) {
              this.page -= 1;
            }
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 更改是否启用回收员
    isEnable(data) {
      this.$axios
        .post(this.$api.O2O.recycler.setOpen, {
          id: data.id,
          is_open: data.is_open,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
